<!-- @Author: Yu_Bo -->
<template>
	<div class='drama_details' v-loading='loading'>
    <div class="left">
      <div class="img">
        <el-image style="width: 100%; height: 100%" :src="cover_url" fit="cover"></el-image>
      </div>
      <div class="info">
        <div class="info_name">{{name}}</div>
        <div class="info_txt">{{subtitle}}</div>
        <div class="info_money" v-if="!has_buy">
          {{diamond}}钻<img src="@/assets/images/icon/diamond_icon.png" alt="">
        </div>
        <div class="info_btn" v-if="!has_buy" @click="affirmBtn">
          <el-button class='btnBgColor_blue' type="primary" :loading='btnLoading'>立即购买</el-button>
        </div>
        <div class="info_btn" v-else @click="quoteBtn">引用台本</div>
        <div class="info_num">
          <img src="../../../assets/images/work/tiben_icon1.png" alt="">
          {{popularity || 0}}人气
        </div>
      </div>
    </div>
    <div class="right">
      <div class="nav">
        <span :class="navIndex==index?'sapn_active':''" v-for="(item,index) in navList" :key="index" @click="navBtn(index)">{{item}}</span>
      </div>
      <div class="right_main scroll_bar_x" id="rightMain">
        <div id="mainTop">
          <div class="title">台本预览</div>
          <div class="sound_audio" v-if="is_sound==2">
            <audio :src="sound_url" ref="audio" controls="controls" loop="loop"></audio>
          </div>
          <div class="text">
            <div class="t_txt">{{description}}</div>
            <div class="t_tip" v-if="!has_buy">
              <div class="tip">
                <el-divider>购买台本查看完整内容</el-divider>
              </div>
            </div>
          </div>
        </div>
        <div class="title" v-if="bright_spot">台本亮点</div>
        <div class="content" v-if="bright_spot">
          <img :src="bright_spot" alt="">
        </div>
      </div>
    </div>
    <!-- 钻石支付 -->
    <pay-diamond ref='dialogdiamond' @renovate='renovateBtn'></pay-diamond>
	</div>
</template>

<script>
  import { mapState } from "vuex"
  import PayDiamond from '@/components/pay_page/pay_diamond.vue'//钻石支付
	export default {
		components: {PayDiamond},
		props: {},
		data() {
			return{
        id:'',
        loading:false,
        //
        cover_url:'',
        name:'',
        subtitle:'',
        diamond:'',
        has_buy:'',
        popularity:"",
        //
        is_sound:'',
        sound_url:"",
        description:'',
        bright_spot:'',
        //
        navIndex:0,
        //
        btnLoading:false,
			}
		},
		computed: {
      navList(){
        if(this.bright_spot){
          return ['台本详情','台本亮点']
        }else{
          return ['台本详情']
        }
      },
      ...mapState({
          userInfo: state => state.userInfo.user_info, // 用户信息
      }),
    },
		watch: {},
		created() {
      this.id = this.$route.params.id
      if (this.id) {
        this.getDetails()
      }
      // 详情页导航
      var name = this.$route.meta.title
      var key = this.$route.meta.key + '_' + this.$route.params.id
      this.$store.dispatch('tabsArr/getName', { name, key })
    },
		mounted() {},
		methods: {
      // 详情
      getDetails(){
        var that = this
        that.loading=true
        that.$workbenApi.marketDetails(that.id).then(res => {
          that.loading=false
          if (res.code == 1000) {
            that.cover_url=res.result.cover_url
            that.name=res.result.name
            that.subtitle=res.result.subtitle
            that.diamond=res.result.diamond
            that.has_buy=res.result.has_buy
            that.popularity=res.result.popularity
            that.is_sound=res.result.is_sound
            that.sound_url=res.result.sound_url
            that.description=res.result.description
            that.bright_spot=res.result.bright_spot
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      // 切换
      navBtn(index){
        var that = this
        that.navIndex=index
        //
        var div = document.getElementById('rightMain')
        var divtop = document.getElementById('mainTop')
        if(index==0){
          that.$nextTick(()=>{
            div.scrollTop = 0
          })
        }else{
          that.$nextTick(()=>{
            div.scrollTop = divtop.scrollHeight
          })
        }
      },
      // 立即购买
      affirmBtn(){
        var that = this
        that.btnLoading=true
        var params = {
          id:that.id
        }
        that.$workbenApi.marketScriptAddOrder(params).then(res => {
          that.btnLoading=false
          if (res.code == 1000) {
            // 钻石支付
            var obj = {
              order_num:res.result.order_num,
              name:that.name,
              diamond:that.userInfo.diamond || 0,
              price:that.diamond,
              id:that.id,
            }
            that.$refs.dialogdiamond.openDialogBtn(obj,7)
          } else {
            that.$errMsg(res.message)
          }
        })

      },
      // 钻石支付关闭刷新
      renovateBtn(){
        this.getDetails()
        this.$store.dispatch('userInfo/getUserInfo')
      },
      // 引用台本
      quoteBtn(){
        this.$router.push({
          path:'/workben/drama/-2',
          query:{
            id:this.id
          }
        })
      },
    },
	}
</script>

<style lang='scss' scoped>
	.drama_details{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    .left{
      width: 330px;
      height: 100%;
      padding: 40px;
      background: url(~@/assets/images/work/left_tiben_bg.png) no-repeat;
      background-size: 100% 100%;
      .img{
        width: 100%;
        height: 293px;
        border-radius: 10px;
        overflow: hidden;
      }
      .info{
        width: 100%;
        .info_name{
          padding-top: 16px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .info_txt{
          padding-top: 6px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
        }
        .info_money{
          padding-top: 20px;
          display: flex;
          align-items: center;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #2E4BF2;
          line-height: 28px;
          img{
            display: block;
            width: 20px;
            height: 20px;
            margin-top: 5px;
            margin-left: 5px;
          }
        }
        .info_btn{
          cursor: pointer;
          width: 100%;
          margin-top: 20px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          background: linear-gradient(90deg, #4875FF 0%, #2B4AFE 100%);
          border-radius: 2px;
          .el-button{
            width: 100%;
            height: 100%;
          }
        }
        .info_num{
          width: 100%;
          padding-top: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          img{
            display: block;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    .right{
      width: calc(100% - 350px);
      height: 100%;
      padding: 30px 0 30px 30px;
      background: #fff;
      border-radius: 10px;
      .nav{
        width: 100%;
        padding-bottom: 20px;
        display: flex;
        span{
          cursor: pointer;
          width: 88px;
          height: 34px;
          line-height: 32px;
          text-align: center;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          border: 1px solid #B2B2B2;
        }
        span:nth-child(2){
          border-radius: 0px 4px 4px 0px;
          border-left: none;
        }
        span:first-child{
          border-radius: 4px 0px 0px 4px;
          border-right: 1px solid #2E4BF2;
        }
        .sapn_active{
          color: #2E4BF2;
          background: rgba(46,75,242,0.1);
          border: 1px solid #2E4BF2;
        }
      }
      .right_main{
        height: calc(100% - 60px);
        padding-right: 30px;
        overflow-y: auto;
      }
      .title{
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .sound_audio{
        width: 100%;
        height: 50px;
        margin-top: 20px;
        audio{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .text{
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 20px 20px 20px 20px;
        background: #F4F4F6;
        border-radius: 2px;
        .t_txt{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
        }
        .t_tip{
          width: 100%;
          .tip{
            width: 400px;
            margin: 30px auto 0;
            ::v-deep .el-divider__text{
              background-color: #F4F4F6;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
            }
          }
        }
      }
      .content{
        width: 100%;
        padding-top: 20px;
        img{
          display: block;
          width: 100%;
        }
      }
    }
	}
</style>
